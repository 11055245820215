export default function Footer(footerData) {
    let options = footerData.footer.data.siteOptions.siteOptions;
    let menuItems = footerData.footer?.data?.menu.menuItems.edges;

    return (
    <footer>
        <div id="stoneleigh-top-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <div id="stoneleigh-locations">
                            <div>
                                <a href="/">
                                    <img width={144} height={52} src="/assets/images/footer-logo.svg" alt="Natural Lands" />
                                </a>
                                <div className="address" dangerouslySetInnerHTML={{__html: options.mobileAddress}}></div>
                                <div className="footer-social-container">
                                    {
                                        options.facebookLink && (
                                            <a href={`${options.facebookLink}`} target="_blank">
                                                <span className="icon-facebook"></span>
                                            </a>
                                        )
                                    }
                                    {
                                        options.instagramLink && (
                                            <a href={`${options.instagramLink}`} target="_blank">
                                                <span className="icon-instagram"></span>
                                            </a>
                                        )
                                    }
                                    {
                                        options.linkedinLink && (
                                            <a href={`${options.linkedinLink}`} target="_blank">
                                                <span className="icon-linkedin"></span>
                                            </a>
                                        )
                                    }
                                    {
                                        options.tiktokLink && (
                                            <a href={`${options.tiktokLink}`} target="_blank">
                                                <span className="icon-tiktok"></span>
                                            </a>
                                        )
                                    }
                                </div>
                            </div>
                            <div>
                                <a href="/stoneleigh">
                                    <img width={144} height={52} src="/assets/images/footer-logo-stoneleigh.webp" alt="Stoneleigh a Natural Garden" />
                                </a>
                                <div className="address" dangerouslySetInnerHTML={{__html: options.stoneleighAddress}}></div>
                                <div className="footer-social-container">
                                    {
                                        options.stoneleighFacebookUrl && (
                                            <a href={`${options.stoneleighFacebookUrl}`} target="_blank">
                                                <span className="icon-facebook"></span>
                                            </a>
                                        )
                                    }
                                    {
                                        options.stoneleighInstagramUrl && (
                                            <a href={`${options.stoneleighInstagramUrl}`} target="_blank">
                                                <span className="icon-instagram"></span>
                                            </a>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="footer-disclaimer" dangerouslySetInnerHTML={{__html: options.disclaimer}}></div>
                        <div id="footer-logos">
                            {
                                options.stoneleighLogos && options.stoneleighLogos.length > 0 && options.stoneleighLogos.map((item, index) => (
                                    <div key={index}>
                                        {
                                            item.link && (
                                                <a href={`${item.link}`} className='custom-link' target="_blank">
                                                    <img src={`${item.logo.sourceUrl}`} />
                                                </a>
                                            )
                                        }
                                        {
                                            !item.link && (
                                                <img src={`${item.logo.sourceUrl}`} />
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div id="stoneleigh-footer-form">
                        <div dangerouslySetInnerHTML={{__html: options.stoneleighForm}}></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="stoneleigh-bottom-footer-container" className="container-fluid">
            <div className="container xlarge-container">
                <div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: options.copyrightContent}}></div>
                    </div>
                    <div>
                        <ul>
                            {
                                menuItems && menuItems.length > 0 && menuItems.map((item, index) => (
                                    <li key={index}>{
                                        <a href={`${item.node.path}`}>
                                            <span className="icon-arrow"></span>
                                            {item.node.label}
                                        </a>
                                    }</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div>
                        <a aria-label="Handcrafted by (opens link in a new tab)" href="https://cacpro.com" target="_blank">
                            Handcrafted by 
                            <span className="icon-cacpro"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}